/* tslint:disable */
/* eslint-disable */

@font-face {
  font-family: 'Aeonik-Regular';
  src: local('Aeonik-Regular'),
    url('fonts/Aeonik/Aeonik-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Jeullyta';
  src: local('Jeullyta'), url('fonts/Jeullyta/Jeullyta.ttf') format('opentype');
}
