@import 'color_variables';

html {
  font-size: 16px !important;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  min-height: 100vh;
  color: $text-color;
  font-family: 'SF Pro Display', sans-serif !important;
}

.otp-input:focus,
.otp-input:focus-visible {
  border: 1.5px solid #E77A0C !important;
  outline: none
}

.tooltip-class {
  border: none !important;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  background-color: #edfdf0 !important;
  border: 1px solid #c7eacf;
  color: #219653 !important;
  font-size: 14px !important;
  text-align: center;
}


.blue {
  color: white;
  background-color: hsl(216, 92%, 57%),
}

::-webkit-scrollbar {
  display: none;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(202, 202, 202) transparent;
}

/* IE/Edge */
* {
  -ms-overflow-style: auto;
}

.invalid,
.valid {
  gap: .5rem;
}

.invalid img,
.valid img {
  width: 16px;
  height: 16px;
}

.invalid span,
.valid span {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: "1rem";
  padding-top: 0rem !important;
}

.invalid:not(:last-child),
.valid:not(:last-child) {
  margin-bottom: .75rem !important;
}

.valid span {
  color: rgba(66, 151, 94, 1)
}

.invalid span {
  color: rgba(128, 131, 163, 1);
}