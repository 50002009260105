.layout {
  width: 80%;
  background: rgba(252, 252, 253, 1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  font-family: 'SF Pro Display';
  color: #000000;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 75%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.date {
  font-weight: 400;
  font-size: 14px;
  margin-left: 18px;
}

h4 {
  margin-bottom: -8px;
}

p {
  font-size: 0.875rem;
  font-family: 'SF Pro Display';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 22px;
}

.definitions {
  padding-left: 5px;
}

.push-left {
  padding-left: 14px;
}

.bottom-content {
  font-family: 'Aeonik-Regular';
  border-top: 2px solid #f1f1f1;
}

.bold {
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  .layout {
    width: 100%;
  }

  .content {
    width: 90%;
  }
}
